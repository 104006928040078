@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?raf02q');
  src:  url('fonts/icomoon.eot?raf02q#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?raf02q') format('truetype'),
    url('fonts/icomoon.woff?raf02q') format('woff'),
    url('fonts/icomoon.svg?raf02q#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alarm:before {
  content: "\e930";
}
.icon-closet:before {
  content: "\e931";
}
.icon-coffee_machine:before {
  content: "\e932";
}
.icon-couch:before {
  content: "\e933";
}
.icon-hairdryer:before {
  content: "\e935";
}
.icon-shower:before {
  content: "\e936";
}
.icon-sink:before {
  content: "\e937";
}
.icon-slippers:before {
  content: "\e938";
}
.icon-sound:before {
  content: "\e939";
}
.icon-toilet:before {
  content: "\e93a";
}
.icon-towels:before {
  content: "\e93b";
}
.icon-info:before {
  content: "\e92d";
}
.icon-check2:before {
  content: "\e92e";
}
.icon-wifi:before {
  content: "\e92f";
}
.icon-group:before {
  content: "\e92c";
}
.icon-employees:before {
  content: "\e92b";
}
.icon-minibar:before {
  content: "\e92a";
}
.icon-champagne:before {
  content: "\e923";
}
.icon-cityscape:before {
  content: "\e924";
}
.icon-balcony:before {
  content: "\e925";
}
.icon-desk:before {
  content: "\e926";
}
.icon-bathtub:before {
  content: "\e927";
}
.icon-toiletries:before {
  content: "\e928";
}
.icon-toilet-paper:before {
  content: "\e929";
}
.icon-bed-dopio:before {
  content: "\e922";
}
.icon-bags:before {
  content: "\e900";
  color: #575756;
}
.icon-bed:before {
  content: "\e901";
}
.icon-bed2:before {
  content: "\e902";
  color: #575756;
}
.icon-bell:before {
  content: "\e903";
}
.icon-bell2:before {
  content: "\e904";
  color: #575756;
}
.icon-burger:before {
  content: "\e905";
}
.icon-caffee:before {
  content: "\e906";
}
.icon-card:before {
  content: "\e907";
}
.icon-dnd-hanger:before {
  content: "\e908";
  color: #575756;
}
.icon-door-hanger:before {
  content: "\e909";
}
.icon-drink:before {
  content: "\e90a";
}
.icon-drinks:before {
  content: "\e90b";
}
.icon-glob-book1:before {
  content: "\e912";
}
.icon-hanger:before {
  content: "\e90d";
}
.icon-hotel-building:before {
  content: "\e90e";
}
.icon-hotel-building2:before {
  content: "\e90f";
  color: #575756;
}
.icon-icecream:before {
  content: "\e910";
}
.icon-keys:before {
  content: "\e911";
}
.icon-majordom:before {
  content: "\e921";
  color: #575756;
}
.icon-monitor:before {
  content: "\e913";
  color: #575756;
}
.icon-navigator:before {
  content: "\e914";
}
.icon-paper:before {
  content: "\e915";
  color: #575756;
}
.icon-phone:before {
  content: "\e916";
}
.icon-receptionist:before {
  content: "\e917";
  color: #575756;
}
.icon-restaurant:before {
  content: "\e918";
}
.icon-restaurant2:before {
  content: "\e919";
  color: #575756;
}
.icon-room-door:before {
  content: "\e91a";
  color: #575756;
}
.icon-room-key:before {
  content: "\e91b";
  color: #575756;
}
.icon-servig-table:before {
  content: "\e91c";
  color: #575756;
}
.icon-tv:before {
  content: "\e91d";
}
.icon-washing:before {
  content: "\e91e";
}
.icon-bag:before {
  content: "\e91f";
}
.icon-glob-book:before {
  content: "\e920";
}
.icon-back-array:before {
  content: "\e90c";
  color: #53576f;
}
.icon-phone1:before {
  content: "\e942";
}
.icon-enter:before {
  content: "\ea13";
}
